const API_URL = '/API'
const ACCOUNT_CONTROLLER = '/Account'
const ARTICLE_CONTROLLER = '/Article'
const DATA_CONTROLLER = '/Data'
const EMAIL_CONTROLLER = '/Email'
const EVENTS_CONTROLLER = '/Events'
const ORGANIZATION_CONTROLLER = '/Organization'
const REGISTRATION_CONTROLLER = '/Registration'
const SUBSCRIBER_CONTROLLER = '/Subscriber'
const REPORT_CONTROLLER = '/Report'

// Account Controller
export const GET_ACCOUNT =
  `${API_URL}${ACCOUNT_CONTROLLER}/Get`
export const FORGOT_PASSWORD =
  `${API_URL}${ACCOUNT_CONTROLLER}/ForgotPassword`
export const RESET_PASSWORD =
  `${API_URL}${ACCOUNT_CONTROLLER}/ResetPassword`
export const LOGIN =
  `${API_URL}${ACCOUNT_CONTROLLER}/Login`
export const LOGOFF =
  `${API_URL}${ACCOUNT_CONTROLLER}/Logoff`

// Article Controller
export const ARTICLE =
  ({ } = {}, ...[id]: Array<string>) =>
    `${API_URL}${ARTICLE_CONTROLLER}/Article${id ? `?id=${id}` : ``}`
export const HOME_ARTICLES =
  `${API_URL}${ARTICLE_CONTROLLER}/HomeArticles`
export const PUBLIC_ARTICLES =
  `${API_URL}${ARTICLE_CONTROLLER}/PublicArticles`
export const ARTICLES =
  `${API_URL}${ARTICLE_CONTROLLER}/Articles`
export const DUPLICATE_ARTICLE =
  `${API_URL}${ARTICLE_CONTROLLER}/Duplicate`
export const DELETE_ARTICLE =
  ({ } = {}, ...[id]: Array<string>) =>
    `${API_URL}${ARTICLE_CONTROLLER}/Delete?id=${id}`

// Data Controller
export const UPLOAD_CERTIFICATE =
  ({ } = {}, ...[id]: Array<string>) =>
    `${API_URL}${DATA_CONTROLLER}/UploadCertificate?id=${id}`
export const APPLICANT_CERTIFICATE =
  ({ } = {}, ...[id, fileName]: Array<string>) =>
    `${API_URL}${DATA_CONTROLLER}/ApplicantCertificate?id=${id}&fileName=${fileName}`
export const EVENT_FILE =
  ({ } = {}, ...[id, fileName]: Array<string>) =>
    `${API_URL}${DATA_CONTROLLER}/EventFile?id=${id}&fileName=${fileName}`
export const ORGANIZATION_NAMES =
  `${API_URL}${DATA_CONTROLLER}/OrganizationNames`
export const DEPARTMENT_NAMES =
  `${API_URL}${DATA_CONTROLLER}/DepartmentNames`
export const CAREER_NAMES =
  `${API_URL}${DATA_CONTROLLER}/CareerNames`
export const EDUCATION_TYPES =
  `${API_URL}${DATA_CONTROLLER}/EducationTypes`
export const SECTOR_TYPES =
  `${API_URL}${DATA_CONTROLLER}/SectorTypes`
export const INDUSTRY_TYPES =
  `${API_URL}${DATA_CONTROLLER}/IndustryTypes`
export const ORGANIZATION_FILE =
  ({ } = {}, ...[fileId]: Array<string>) =>
    `${API_URL}${DATA_CONTROLLER}/OrganizationFile?fileId=${fileId}`
export const DOWNLOAD =
  ({ } = {}, ...[fileId]: Array<string>) =>
    `${API_URL}${DATA_CONTROLLER}/Download?id=${fileId}`
export const EVENT_VALIDITY =
  ({ } = {}, ...[eventId]: Array<string>) =>
    `${API_URL}${DATA_CONTROLLER}/isEventValid?EventId=${eventId}`
export const SUGGEST_PROGRAM =
  `${API_URL}${DATA_CONTROLLER}/SuggestProgram`
export const GET_APPLICANTS =
  ({ } = {}, ...[eventId]: Array<string>) =>
    `${API_URL}${DATA_CONTROLLER}/GetApplicants?eventId=${eventId}`
export const ACCEPTED_APPLICANT_COUNT =
  ({ } = {}, ...[eventId]: Array<string>) =>
    `${API_URL}${DATA_CONTROLLER}/NumberOfAcceptedApplicants?eventId=${eventId}`
export const INDIVIDUALS =
  `${API_URL}${DATA_CONTROLLER}/Individuals`
export const GET_APPROVALS =
  `${API_URL}${DATA_CONTROLLER}/GetApprovals`
export const FINALIZE_APPROVALS =
  `${API_URL}${DATA_CONTROLLER}/FinalizeApprovals`
export const EMPLOYEES =
  `${API_URL}${DATA_CONTROLLER}/Employees`
export const ORGANIZATION_PROFILE =
  ({ } = {}, ...[orgId]: Array<string>) =>
    `${API_URL}${DATA_CONTROLLER}/OrganizationProfile${orgId ? `?orgId=${orgId}` : ``}`
export const ORGANIZATION_REPRESENTATIVES =
  ({ } = {}, ...[orgId]: Array<string>) =>
    `${API_URL}${DATA_CONTROLLER}/OrganizationRepresentatives${orgId ? `?orgId=${orgId}` : ``}`
export const USER_ROLE =
  `${API_URL}${DATA_CONTROLLER}/UserRole`
export const GET_APPLICATIONS =
  ({ } = {}, ...[userId]: Array<string>) =>
    `${API_URL}${DATA_CONTROLLER}/GetApplications${userId ? `?id=${userId}` : ``}`
export const USER_PROFILE =
  ({ } = {}, ...[userId]: Array<string>) =>
    `${API_URL}${DATA_CONTROLLER}/UserProfile${userId ? `?userId=${userId}` : ``}`
export const APP_FILE =
  ({ } = {}, ...[id]: Array<string>) =>
    `${API_URL}${DATA_CONTROLLER}/AppFile${id ? `?id=${id}` : ``}`
export const APP_DATA =
  ({ } = {}, ...[AppId]: Array<string>) =>
    `${API_URL}${DATA_CONTROLLER}/AppData${AppId ? `?AppId=${AppId}` : ``}`
export const ORGANIZATION_CONTRIBUTOR =
  `${API_URL}${DATA_CONTROLLER}/OrganizationContributor`
export const VERIFY_USER =
  `${API_URL}${DATA_CONTROLLER}/VerifyUser`

// Events Controller
export const PUBLIC_EVENTS =
  ({ } = {}, ...[limit]: Array<string>) =>
    `${API_URL}${EVENTS_CONTROLLER}/PublicEvents${limit ? `?limit=${limit}` : ``}`
export const EVENT_TYPE =
  ({ } = {}, ...[eventId]: Array<string>) =>
    `${API_URL}${EVENTS_CONTROLLER}/Type?Id=${eventId}`
export const PUBLIC_EVENT =
  ({ } = {}, ...[id]: Array<string>) =>
    `${API_URL}${EVENTS_CONTROLLER}/PublicEvent?id=${id}`
export const EVENT_CLASSIFICATION =
  `${API_URL}${EVENTS_CONTROLLER}/Classification`
export const ACCEPTED_APPLICANT =
  ({ } = {}, ...[eventId]: Array<string>) =>
    `${API_URL}${EVENTS_CONTROLLER}/AcceptedApplicants?id=${eventId}`
export const EVENT_TITLE =
  ({ } = {}, ...[eventId]: Array<string>) =>
    `${API_URL}${EVENTS_CONTROLLER}/EventTitle?id=${eventId}`
export const EVENT_CAPACITY =
  ({ } = {}, ...[eventId]: Array<string>) =>
    `${API_URL}${EVENTS_CONTROLLER}/EventCapacity?id=${eventId}`
export const EVENT_ACCEPTED_APPLICANTS =
  ({ } = {}, ...[eventId]: Array<string>) =>
    `${API_URL}${EVENTS_CONTROLLER}/EventAcceptedApplicants?id=${eventId}`
export const EVENT =
  ({ } = {}, ...[id]: Array<string>) =>
    `${API_URL}${EVENTS_CONTROLLER}/Event${id ? `?id=${id}` : ``}`
export const EVENT_TYPES =
  `${API_URL}${EVENTS_CONTROLLER}/Types`
export const EVENTS =
  `${API_URL}${EVENTS_CONTROLLER}/Events`
export const DELETE_EVENT =
  ({ } = {}, ...[id]: Array<string>) =>
    `${API_URL}${EVENTS_CONTROLLER}/Delete?id=${id}`
export const DUPLICATE_EVENT =
  `${API_URL}${EVENTS_CONTROLLER}/Duplicate`
export const APPLICANTS_WITHDRAW =
  `${API_URL}${EVENTS_CONTROLLER}/ApplicantsWithdraw`
export const APPLICANTS_APPROVAL =
  `${API_URL}${EVENTS_CONTROLLER}/ApplicantsApproval`
export const APPLICANTS_DETAILS =
  `${API_URL}${EVENTS_CONTROLLER}/ApplicantsDetails`
export const APPLICANTS_SCORE =
  `${API_URL}${EVENTS_CONTROLLER}/ApplicantsScore`
export const APPLICANTS_ATTENDANCE =
  `${API_URL}${EVENTS_CONTROLLER}/ApplicantsAttendance`
export const APPLICANTS_FEEDBACK =
  `${API_URL}${EVENTS_CONTROLLER}/ApplicantsFeedback`
export const EVENT_EXTRA_DATA =
  ({ } = {}, ...[eventId]: Array<string>) =>
    `${API_URL}${EVENTS_CONTROLLER}/ExtraData?Id=${eventId}`
export const EVENTS_FILTERS =
  `${API_URL}${EVENTS_CONTROLLER}/Filters`

// Organization Controller
export const ORGANIZATION_LIST =
  `${API_URL}${ORGANIZATION_CONTROLLER}/OrganizationList`
export const ORGANIZATION_APPROVAL =
  `${API_URL}${ORGANIZATION_CONTROLLER}/OrganizationApproval`
export const ORGANIZATIONS_NAMES =
  `${API_URL}${ORGANIZATION_CONTROLLER}/OrganizationsNames`

// Registration Controller
export const INDIVIDUAL_REGISTRATION =
  `${API_URL}${REGISTRATION_CONTROLLER}/Individual`
export const ORGANIZATION_REGISTRATION =
  `${API_URL}${REGISTRATION_CONTROLLER}/Organization`
export const APPLICATION_REGISTRATION =
  `${API_URL}${REGISTRATION_CONTROLLER}/Application`

// Subscriber Controller
export const SUBSCRIBE =
  `${API_URL}${SUBSCRIBER_CONTROLLER}/Subscribe`
export const SUBSCRIBER_LIST =
  `${API_URL}${SUBSCRIBER_CONTROLLER}/List`
export const DELETE_SUBSCRIBER =
  ({ } = {}, ...[id]: Array<string>) =>
    `${API_URL}${SUBSCRIBER_CONTROLLER}/Delete?id=${id}`

// Report Controller
export const ADMIN_REPORT =
  `${API_URL}${REPORT_CONTROLLER}/GetAdminData`
export const HR_REPORT =
  `${API_URL}${REPORT_CONTROLLER}/GetHRData`