import * as React from 'react'
import { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'
import Spinner from '../../components/Spinner/Spinner'
import { UserProfileView } from '../../types/profile'
import {
  ColumnDef,
  getCoreRowModel,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable
} from '@tanstack/react-table'
import { individualsTableColumns } from '../../data/IndividualsTableColumns'
import { ArrowClockwise, Download } from 'react-bootstrap-icons'
import Table from '../../components/Table/Table'
import Toolbar from '../../components/Toolbar/Toolbar'
import { downloadCSVFile, escapeData } from '../../utils/csv'

type EmployeesProps = {}

const Employees = ({ }: EmployeesProps) => {
  const [individuals, setIndividuals] = useState<Array<UserProfileView>>([])
  const [loading, setLoading] = useState(true)
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [sorting, setSorting] = React.useState<SortingState>([])

  const getEmployees = async () => {
    const [employees] = await Promise.all([
      fetch('/api/data/employees').then((response) => response.json()),
    ])
    setIndividuals(employees)
    setLoading(false)
  }
  useEffect(() => { getEmployees() }, [])

  const reload = async () => {
    setLoading(true)
    await getEmployees()
    setLoading(false)
  }

  const generateCSV = () => {
    let lines = ''
    const columns = [
      'CivilId',
      'FirstNameEn',
      'MiddleNameEn',
      'LastNameEn',
      'FirstNameAr',
      'MiddleNameAr',
      'LastNameAr',
      'WorkEmail',
      'OtherEmail',
      'Nationality',
      'Telephone',
      'Department',
      'Title',
      'Career',
      'Education',
      'CV',
    ]
    individuals.forEach((individual, index) => {
      const tuples = Object.entries(individual)
      if (index === 0) {
        for (const [key, value] of tuples) {
          if (!columns.includes(key)) { continue }
          lines += `"${escapeData(key)}",`
        }
        lines += '\n'
      }
      for (const [key, value] of tuples) {
        if (!columns.includes(key)) { continue }
        if (key === 'CV')
          lines +=
            value !== '00000000-0000-0000-0000-000000000000'
              ? `"https://learn.kfas.org.kw/api/data/download?id=${escapeData(value as string)}",`
              : `"Unsubmitted",`
        else
          lines += value ? `"${escapeData(value as string)}",` : `"",`
      }
      lines += '\n'
    })
    const today = new Date()
    const date = `${today.getFullYear()}${today.getMonth() + 1}${today.getDate()}`
    downloadCSVFile(lines, `${date} - Employees`)
    setToastMessage('Download Successful✅')
    setShowToast(true)
  }

  const columns = React.useMemo<ColumnDef<UserProfileView>[]>(
    () => individualsTableColumns({}), [])
  const table = useReactTable({
    data: individuals,
    columns,
    state: { sorting, },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })
  if (loading) return (<Spinner />)
  return (
    <>
      <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center position-relative mb-2">
        <h1 className="display-6 text-primary">Employees</h1>
        <Toolbar>
          <Button onClick={reload} title="Reload" className="">
            <ArrowClockwise />
            <div><small>Reload</small></div>
          </Button>
          <Button onClick={generateCSV}>
            <Download />
            <div><small>Download</small></div>
          </Button>
        </Toolbar>
      </div>
      <Table table={table} />
      <ToastContainer className="p-3 position-fixed bottom-0 end-0">
        <Toast
          className="ms-auto"
          show={showToast}
          onClose={() => setShowToast(false)}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">KFAS Learn</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  )
}

export default Employees
