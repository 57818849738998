import * as React from 'react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Spinner from '../../components/Spinner/Spinner'
import { ApplicationView } from '../../types/application'
import Button from 'react-bootstrap/Button'
import { submitForm } from '../../utils/forms'
import {
  ColumnDef,
  getCoreRowModel,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable
} from '@tanstack/react-table'
import { applicationsTableColumns } from '../../data/ApplicationsTableColumns'
import { ArrowClockwise } from 'react-bootstrap-icons'
import Table from '../../components/Table/Table'
import Toolbar from '../../components/Toolbar/Toolbar'
import { APPLICANTS_WITHDRAW, GET_APPLICATIONS } from '../../API'

type ApplicationsProps = {}

const Applications = ({ }: ApplicationsProps) => {
  const { id: id } = useParams()
  const [loading, setLoading] = useState(true)
  const [applications, setApplications] = useState<Array<ApplicationView>>([])
  const [acceptedApplications, setAcceptedApplications] = useState<Array<ApplicationView>>([])
  const [sorting, setSorting] = React.useState<SortingState>([])

  const getApplications = async () => {
    const [applicationsData] = await Promise.all([
      fetch(GET_APPLICATIONS`${id ?? ''}`).then((response) => response.json()),
    ])
    setApplications(applicationsData)
    setAcceptedApplications(applicationsData.filter((application: ApplicationView) =>
      application.Status === 'Accepted' &&
      new Date(application.EventFromDate) >= new Date()
    ))
    setLoading(false)
  }
  useEffect(() => { getApplications() }, [])

  const reload = async () => {
    setLoading(true)
    await getApplications()
    setLoading(false)
  }

  const handleWithdraw: React.MouseEventHandler = async event => {
    const formData = new FormData()
    const button = event.target as HTMLButtonElement
    const eventId = button.dataset.eventId
    if (!eventId === undefined) { return }
    formData.append('eventId', eventId as string)
    const json = await submitForm(APPLICANTS_WITHDRAW, formData)
    if (json.type === 'SUCCESS') {
      getApplications()
    } else { }
  }

  const columns = React.useMemo<ColumnDef<ApplicationView>[]>(
    () => applicationsTableColumns({ handleWithdraw, disable: id != undefined }), [])
  const table = useReactTable({
    data: applications,
    columns,
    state: { sorting, },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  if (loading) return <Spinner />
  return (
    <>
      <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center position-relative mb-2">
        <h1 className="display-6 text-primary">Applications</h1>
        <Toolbar>
          <Button onClick={reload} title="Reload" className="">
            <ArrowClockwise />
            <div><small>Reload</small></div>
          </Button>
        </Toolbar>
      </div>
      {acceptedApplications.length > 0 && <>
        <div className="fs-4 text-primary">Your upcoming events:</div>
        <ul>
          {acceptedApplications.map((application: ApplicationView) =>
            <li key={application.EventId}>
              {new Date(application.EventFromDate).toLocaleDateString('en-GB')}:{' '}
              <a href={`/event-list/${application.EventId}`}>{application.Event}</a>
            </li>
          )}
        </ul>
      </>}
      <Table table={table} />
    </>
  )
}

export default Applications
