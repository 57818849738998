import * as React from 'react'
import { Link } from 'react-router-dom'
import { UserProfileView } from '../types/profile'
import { ColumnDef } from '@tanstack/react-table'
import { Form } from 'react-bootstrap'
import { submitForm } from '../utils/forms'

type IndividualsTableColumnsProps = {}

const individualsTableColumns = ({ }: IndividualsTableColumnsProps): ColumnDef<UserProfileView>[] => [
  {
    header: () => <span>First Name</span>,
    accessorKey: 'FirstNameEn',
    accessorFn: row => ({ User: row.Id, FirstName: row.FirstNameEn }),
    id: 'FirstNameEn',
    cell: info => {
      const { User, FirstName } = info.getValue<{ User: string, FirstName: string }>()
      return <Link to={`/profile/${User}`}>{FirstName}</Link>
    },
    filterFn: (row, columnId, filterValue) => {
      const search = filterValue.toLowerCase()
      const { FirstName } = row.getValue<{ User: string, FirstName: string }>(columnId)
      return Boolean(FirstName.toLowerCase().includes(search))
    },
    sortingFn: (current: any, next: any) => {
      const currentFirstName = current.original.FirstNameEn.toLowerCase()
      const nextFirstName = next.original.FirstNameEn.toLowerCase()
      return currentFirstName === nextFirstName
        ? 0
        : currentFirstName > nextFirstName
          ? 1
          : -1
    },
  },
  {
    header: () => <span>Middle Name</span>,
    accessorKey: 'MiddleNameEn',
    accessorFn: row => ({ User: row.Id, MiddleName: row.MiddleNameEn }),
    id: 'MiddleNameEn',
    cell: info => {
      const { User, MiddleName } = info.getValue<{ User: string, MiddleName: string }>()
      return <Link to={`/profile/${User}`}>{MiddleName}</Link>
    },
    filterFn: (row, columnId, filterValue) => {
      const search = filterValue.toLowerCase()
      const { MiddleName } = row.getValue<{ User: string, MiddleName: string }>(columnId)
      return Boolean(MiddleName.toLowerCase().includes(search))
    },
    sortingFn: (current: any, next: any) => {
      const currentMiddleName = current.original.MiddleNameEn.toLowerCase()
      const nextMiddleName = next.original.MiddleNameEn.toLowerCase()
      return currentMiddleName === nextMiddleName
        ? 0
        : currentMiddleName > nextMiddleName
          ? 1
          : -1
    },
  },
  {
    header: () => <span>Last Name</span>,
    accessorKey: 'LastNameEn',
    accessorFn: row => ({ User: row.Id, LastName: row.LastNameEn }),
    id: 'LastNameEn',
    cell: info => {
      const { User, LastName } = info.getValue<{ User: string, LastName: string }>()
      return <Link to={`/profile/${User}`}>{LastName}</Link>
    },
    filterFn: (row, columnId, filterValue) => {
      const search = filterValue.toLowerCase()
      const { LastName } = row.getValue<{ User: string, LastName: string }>(columnId)
      return Boolean(LastName.toLowerCase().includes(search))
    },
    sortingFn: (current: any, next: any) => {
      const currentLastName = current.original.LastNameEn.toLowerCase()
      const nextLastName = next.original.LastNameEn.toLowerCase()
      return currentLastName === nextLastName
        ? 0
        : currentLastName > nextLastName
          ? 1
          : -1
    },
  },
  {
    header: () => <span>Organization</span>,
    accessorKey: 'Organization',
    accessorFn: row => row.Organization,
    id: 'Organization',
    cell: info => info.getValue(),
    sortingFn: 'text',
  },
  {
    header: () => <span>Nationality</span>,
    accessorKey: 'Nationality',
    accessorFn: row => row.Nationality,
    id: 'Nationality',
    cell: info => info.getValue(),
    sortingFn: 'text',
  },
  {
    header: () => <span>Telephone</span>,
    accessorKey: 'Telephone',
    accessorFn: row => row.Telephone,
    id: 'Telephone',
    cell: info => info.getValue(),
    sortingFn: 'text',
  },
  {
    header: () => <span>Work Email</span>,
    accessorKey: 'WorkEmail',
    accessorFn: row => row.WorkEmail,
    id: 'WorkEmail',
    cell: info => info.getValue(),
    sortingFn: 'alphanumeric',
  },
  {
    header: () => <span>Verified</span>,
    accessorKey: 'Id',
    accessorFn: row => ({ User: row.Id, Verified: row.Verified }),
    id: 'Actions',
    cell: info => {
      const { User, Verified } = info.getValue() as { User: string, Verified: boolean }
      return (
        <Form.Check
          onChange={async event => {
            event.target.disabled = true
            const { checked } = event.target
            const formData = new FormData()
            formData.set('User', User)
            formData.set('Verified', `${checked}`)
            const json = await submitForm('api/data/VerifyUser', formData)
            event.target.checked = json.type === 'SUCCESS'
              ? checked
              : !checked
            event.target.disabled = false
          }}
          type="switch"
          id={`verify-${User}`}
          name="Verify"
          title="Verify"
          defaultChecked={Verified}
          data-id={`${User}`}
        />
      )
    },
    enableColumnFilter: false,
    sortingFn: (current: any, next: any) => {
      const currentVerified = current.original.Verified
      const nextVerified = next.original.Verified
      return currentVerified === nextVerified
        ? 0
        : currentVerified > nextVerified
          ? 1
          : -1
    },
  },
  {
    header: () => <span>Applications</span>,
    accessorKey: 'Applications',
    accessorFn: row => row.Id,
    id: 'Applications',
    cell: info => <Link to={`/applications/${info.getValue()}`}>Applications</Link>,
    enableSorting: false,
    enableColumnFilter: false,
  },
]

export {
  individualsTableColumns,
}