import './EventCard.css'
import * as React from 'react'
import { Event } from '../../types/event'
import Card from 'react-bootstrap/Card'
import CardImg from 'react-bootstrap/CardImg'
import Badge from 'react-bootstrap/Badge'
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button'

type EventCardProps = {
  event: Event
}

const EventCard = ({ event }: EventCardProps) => {
  return (
    <Card className="event shadow">
      <CardImg
        as={"div"}
        alt="Event picture"
        style={{
          backgroundImage: `url(${event.Picture})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          aspectRatio: "16 / 9",
          position: "relative"
        }}
      >
      </CardImg>
      <div
        className="d-flex justify-content-around align-items-center w-100 fw-bold fs-5"
        style={{
          backgroundColor: "rgba(0, 0, 0, .6)",
          color: "rgba(255, 255, 255, 1)",
        }}
      >
        <span title="From">{new Date(event.FromDate).toLocaleDateString()}</span>
        <span>{document?.body?.parentElement?.dir === 'rtl' ? '←' : '→'}</span>
        <span title="To">{new Date(event.ToDate).toLocaleDateString()}</span>
      </div>
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center mb-3 gap-1">
          {new Date(`${event.Deadline}+03:00`) > new Date()
            ? <Badge bg="success" className="flex-grow-1">Open</Badge>
            : <Badge bg="danger" className="flex-grow-1">Closed</Badge>}
          <Badge bg="primary" className="flex-grow-1">{event.Classification}</Badge>
          <Badge bg="secondary" className="flex-grow-1">{event.Type}</Badge>
        </div>
        <Card.Title
          className="display-6 fs-4"
          style={{
            height: "3em",
          }}
        >
          {event.Title}
        </Card.Title>
        <Card.Text
          dangerouslySetInnerHTML={{
            __html: `${event.Overview
              ? JSON.parse(event.Overview).blocks.map((item: any) => item.text).join(' ').substring(0, 100)
              : ''}...`
          }}
        />
      </Card.Body>
      <Card.Footer
        className="bg-white border-top-0 p-3"
      >
        <Link to={`/event-list/${event.Id}`}>
          <Button className="">More details</Button>
        </Link>
      </Card.Footer>
    </Card>
  )
}

export default EventCard