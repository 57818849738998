//Redux
import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
} from '@reduxjs/toolkit'
import { LoginForm, Login, Logout } from '../types/auth'
import { saveState, loadState } from './helpers'

export interface AuthState {
  state: String
  role: String //'PUBLIC' | 'AUTHENTICATED' | 'ADMINISTRATOR',
  token?: String | null
  status?: 'IDLE' | 'LOADING' | 'SUCCEEDED' | 'FAILED'
  error?: String
}

const initialState: AuthState = loadState('auth') ?? {
  state: 'LOGGED_OUT',
  role: 'PUBLIC',
  token: null,
  status: 'IDLE'
}

export const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: {
      reducer: (state, action: PayloadAction<AuthState>) => {
        Object.assign(state, action.payload) // Copy payload properties to state properties
        saveState('auth', state)
      },
      prepare: (state: AuthState) => ({ payload: state || initialState })
    },
    getAuth: state => state
  },
  extraReducers(builder) {
    builder
      .addCase(login.pending, (state, action) => {
        state.status = 'LOADING'
      })
      .addCase(login.fulfilled, (state, action: any) => {
        Object.assign(state, action.payload) // Copy payload properties to state properties
        state.status = 'SUCCEEDED'
      })
      .addCase(login.rejected, (state, action) => {
        state.error = action.error.message
        state.status = 'FAILED'
      })
      .addCase(logout.pending, (state, action) => {
        state.status = 'LOADING'
      })
      .addCase(logout.fulfilled, (state, action: any) => {
        Object.assign(state, action.payload) // Copy payload properties to state properties
        state.status = 'SUCCEEDED'
      })
      .addCase(logout.rejected, (state, action) => {
        state.error = action.error.message
        state.status = 'FAILED'
      })
      .addDefaultCase((state, action: any) => {
        Object.assign(state, action.payload || initialState) // Copy payload properties to state properties
        state.status = 'IDLE'
      })
  }
})

export const { setAuth, getAuth } = AuthSlice.actions

const fetchHeaders = {
  'X-XSRF-TOKEN': '',
  'Accept': 'application/json, text/plain',
  'Content-Type': 'application/json;charset=UTF-8',
}

export const login = createAsyncThunk('auth/login', async (formData: LoginForm, thunkAPI) => {
  const appState: any = thunkAPI.getState()
  if (!appState || !appState.auth || appState.auth.state === 'LOGGED_IN') { return }
  const xsrfToken = document.cookie
    .split('; ')
    .find(row => row.startsWith('XSRF-TOKEN='))
    ?.split('=')[1] ?? ''
  const headers = Object.assign({}, fetchHeaders, { 'X-XSRF-TOKEN': xsrfToken }) // Use `fetchHeaders` template and add token.
  const response = await fetch(`/api/account/login`, {
    method: 'POST',
    body: JSON.stringify(formData),
    headers,
  })
  const data: Login = await response.json()
  const { type: state, role } = data //renamed `type` to `state`.
  const token = xsrfToken
  const auth = { state, role, token }
  thunkAPI.dispatch(setAuth(auth))
  return auth
})

export const logout = createAsyncThunk('auth/logout', async (args, thunkAPI) => {
  const appState: any = thunkAPI.getState()
  if (!appState || !appState.auth || appState.auth.state !== 'LOGGED_IN') { return }
  const xsrfToken = document.cookie
    .split('; ')
    .find(row => row.startsWith('XSRF-TOKEN='))
    ?.split('=')[1] ?? ''
  const headers = Object.assign({}, fetchHeaders, { 'X-XSRF-TOKEN': xsrfToken }) // Use `fetchHeaders` template and add token.
  const response = await fetch('/api/account/logoff', {
    method: 'POST',
    headers,
  })
  const data: Logout = await response.json()
  const { type: state, role } = data // Renamed `type` to `state`.
  const token = null
  const auth = { state, role, token }
  thunkAPI.dispatch(setAuth(auth))
  return auth
})

export default AuthSlice.reducer