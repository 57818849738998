import * as React from 'react'
import { Link } from 'react-router-dom'
import { ApplicationView } from '../types/application'
import { ColumnDef } from '@tanstack/react-table'
import { dateFilter } from '../utils/filters'
import { Badge, Button } from 'react-bootstrap'
import { APPLICANT_CERTIFICATE } from '../API'

const STATUS = {
  'null': 'Pending',
  'true': 'Approved',
  'false': 'Rejected',
}

const STATUS_COLOR = {
  'null': 'primary',
  'true': 'success',
  'false': 'secondary',
}

type ApplicationsTableColumnsProps = {
  handleWithdraw: React.MouseEventHandler,
  disable?: boolean
}

const applicationsTableColumns = ({ handleWithdraw, disable }: ApplicationsTableColumnsProps): ColumnDef<ApplicationView>[] => [
  {
    header: 'Event',
    columns: [
      {
        header: () => <span>Event</span>,
        accessorKey: 'Event',
        accessorFn: row => ({ EventId: row.EventId, EventName: row.Event }),
        id: 'Event',
        cell: info => {
          const { EventId, EventName } = info.getValue()
          return <Link to={`/event-list/${EventId}`}>{EventName}</Link>
        },
        filterFn: (row, columnId, filterValue) => {
          const search = filterValue.toLowerCase()
          const { EventName } = row.getValue<{ EventId: number, EventName: string }>(columnId)
          return Boolean(EventName.toLowerCase().includes(search))
        },
        sortingFn: (current: any, next: any) => {
          const currentEvent = current.original.Event.toLowerCase()
          const nextEvent = next.original.Event.toLowerCase()
          return currentEvent === nextEvent
            ? 0
            : currentEvent > nextEvent
              ? 1
              : -1
        },
      },
      {
        header: () => <span>Event Start Date</span>,
        accessorKey: 'EventFromDate',
        accessorFn: row => row.EventFromDate,
        id: 'EventFromDate',
        cell: info => new Date(info.getValue()).toLocaleDateString('en-GB'),
        filterFn: dateFilter,
        sortingFn: 'datetime',
      },
      {
        header: () => <span>Applied On</span>,
        accessorKey: 'CreatedOn',
        accessorFn: row => row.CreatedOn,
        id: 'CreatedOn',
        cell: info => new Date(info.getValue()).toLocaleDateString('en-GB'),
        filterFn: dateFilter,
        sortingFn: 'datetime',
      },
      {
        header: () => <span>HR Approval</span>,
        accessorKey: 'Approved',
        accessorFn: row => row.Approved,
        id: 'Approved',
        cell: info => {
          const HRApproved = info.getValue()
          return <Badge bg={`${STATUS_COLOR[HRApproved as keyof typeof STATUS_COLOR]}`} className="w-100">
            {`${STATUS[HRApproved as keyof typeof STATUS]}`}
          </Badge>
        },
        filterFn: (row, columnId, filterValue) => {
          const search = filterValue.toLowerCase()
          const value = row.getValue(columnId)
          const statusString = STATUS[value as keyof typeof STATUS].toLowerCase()
          return Boolean(statusString.includes(search))
        },
        sortingFn: (current: any, next: any) => {
          const currentStatus = STATUS[current.original.Approved as keyof typeof STATUS].toLowerCase()
          const nextStatus = STATUS[next.original.Approved as keyof typeof STATUS].toLowerCase()
          return currentStatus === nextStatus
            ? 0
            : currentStatus > nextStatus
              ? 1
              : -1
        },
      },
      {
        header: () => <span>Status</span>,
        accessorKey: 'Status',
        accessorFn: row => row.Status ?? 'N/A',
        id: 'Status',
        cell: info => info.getValue(),
        sortingFn: 'text',
      },
      {
        header: () => <span>Feedback</span>,
        accessorKey: 'Feedback',
        accessorFn: row => row.Feedback
          ? 'Completed'
          : 'N/A',
        id: 'Feedback',
        cell: info => info.getValue(),
        sortingFn: 'text',
      },
      {
        header: () => <span>Certificate</span>,
        accessorKey: 'Certificate',
        accessorFn: row => ({ ApplicationId: row.Id, Certificate: row.Certificate, Feedback: row.Feedback }),
        id: 'Certificate',
        cell: info => {
          const { ApplicationId, Certificate, Feedback } = info.getValue()
          return Certificate && Feedback
            ?
            <a download href={APPLICANT_CERTIFICATE`${ApplicationId}${'Certificate'}`}>
              Download
            </a>
            : 'N/A'
        },
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        header: () => <span>Actions</span>,
        accessorFn: row => ({ EventId: row.EventId, Status: row.Status, EventDeadline: row.EventDeadline, UpdatedOn: row.UpdatedOn }),
        id: 'Action',
        cell: info => {
          const { EventId, Status, EventDeadline, UpdatedOn } = info.getValue()
          return (
            <Button
              variant="primary"
              onClick={handleWithdraw}
              data-event-id={EventId}
              disabled={
                !(
                  (Status === "Accepted" || Status === "Pending") &&
                  (Date.now() <= new Date(EventDeadline).getTime() || Date.now() <= new Date(UpdatedOn).getTime())
                  && !disable
                )
              }
            >
              Withdraw
            </Button>
          )
        },
        enableSorting: false,
        enableColumnFilter: false,
      },
    ],
  },
]

export {
  applicationsTableColumns,
}