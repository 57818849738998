import './Subscribe.css'
import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { EnvelopePlus } from 'react-bootstrap-icons'
import { submitForm } from '../../utils/forms'
import { Spinner } from 'react-bootstrap'

type SubscribeProps = {}

const Subscribe = ({ }: SubscribeProps) => {
  const [loading, setLoading] = React.useState(false)
  
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setLoading(true)
    const form = event.target as HTMLFormElement
    const formData = new FormData(form)
    const json = await submitForm(`/api/subscriber/subscribe`, formData)
    if (json.type === "SUCCESS") { form.reset() }
    setLoading(false)
  }

  return (
    <Form className="d-inline-block my-1" onSubmit={onSubmit}>
      Would you like to receive emails from us?
      <Form.Group className="d-flex" controlId="subscribe">
        <Form.Control
          type="email"
          placeholder="Enter your email"
          name="Email"
          className="pb-2"
          disabled={loading}
          required
        />
        <Button
          type="submit"
          className="d-flex align-items-center"
          disabled={loading}
          aria-label="Subscribe"
        >
          {loading
            ?
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            : <EnvelopePlus />
          }
        </Button>
      </Form.Group>
    </Form>
  )
}

export default Subscribe
