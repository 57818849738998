import * as React from 'react'
import { useEffect, useState } from 'react'
import Register from '../../components/Forms/Individual'
import Spinner from '../../components/Spinner/Spinner'
import { UserProfile } from '../../types/profile'
import { useParams } from 'react-router'

export default () => {
  const [loading, setLoading] = useState(true)
  const [profile, setProfile] = useState<UserProfile>()
  const { id: userId } = useParams()

  const getProfile = async () => {
    const response = !userId || userId === ':id'
      ? await fetch(`/api/data/userprofile`)
      : await fetch(`/api/data/userprofile?userId=${userId}`)
    const data = await response.json()
    setProfile(data)
  }
  useEffect(() => {
    getProfile()
    setLoading(false)
  }, [])

  if (loading) return (<Spinner />)
  return (
    <Register
      title="Profile"
      isEditing={true}
      profile={profile} />
  )
}
