import * as React from 'react'
import { useState, useEffect } from 'react'
import Spinner from '../../components/Spinner/Spinner'
import { UserProfileView } from '../../types/profile'
import {
  ColumnDef,
  getCoreRowModel,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable
} from '@tanstack/react-table'
import { individualsTableColumns } from '../../data/IndividualsTableColumns'
import { ArrowClockwise } from 'react-bootstrap-icons'
import Table from '../../components/Table/Table'
import { Button } from 'react-bootstrap'
import Toolbar from '../../components/Toolbar/Toolbar'

type IndividualsProps = {}

const Individuals = ({ }: IndividualsProps) => {
  const [individuals, setIndividuals] = useState<UserProfileView[]>([])
  const [loading, setLoading] = useState(true)
  const [sorting, setSorting] = React.useState<SortingState>([])

  const getIndividuals = async () => {
    const [individualsData] = await Promise.all([
      fetch("/api/data/individuals").then((response) => response.json()),
    ])
    setIndividuals(individualsData)
    setLoading(false)
  }
  useEffect(() => { getIndividuals() }, [])

  const reload = async () => {
    setLoading(true)
    await getIndividuals()
    setLoading(false)
  }

  const columns = React.useMemo<ColumnDef<UserProfileView>[]>(
    () => individualsTableColumns({}), [])
  const table = useReactTable({
    data: individuals,
    columns,
    state: { sorting, },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  if (loading) return (<Spinner />)
  return (
    <>
      <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center position-relative mb-2">
        <h1 className="display-6 text-primary">Individuals</h1>
        <Toolbar>
          <Button onClick={reload} title="Reload" className="">
            <ArrowClockwise />
            <div><small>Reload</small></div>
          </Button>
        </Toolbar>
      </div>
      <Table table={table} />
    </>
  )
}

export default Individuals
