import React from 'react'
import { useState, useEffect } from 'react'
import BootstrapTable from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'
import Spinner from '../../components/Spinner/Spinner'
import { submitForm } from '../../utils/forms'
import {
  ColumnDef,
  getCoreRowModel,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable
} from '@tanstack/react-table'
import { organizationsTableColumns } from '../../data/OrganizationsTableColumns'
import { ArrowClockwise, Save } from 'react-bootstrap-icons'
import Table from '../../components/Table/Table'
import { OrganizationListView } from '../../types/profile'
import Toolbar from '../../components/Toolbar/Toolbar'
import { ORGANIZATION_APPROVAL, ORGANIZATION_LIST } from '../../API'

type OrganizationsProps = {}

type OrganizationApproval = {
  Id: number
  IsApproved: boolean
}

type OrganizationalStatistics = {
  Accepted: number
  Rejected: number
  Pending: number
  Contributor: number
}

const Organizations = ({ }: OrganizationsProps) => {
  //hooks
  const [organizations, setOrganizations] = useState<OrganizationListView[]>([])
  const [organizationApprovals, setOrganizationApprovals] = useState<OrganizationApproval[]>([])
  const [organizationalStatistics, setOrganizationalStatistics] = useState<OrganizationalStatistics>({ Accepted: 0, Rejected: 0, Pending: 0, Contributor: 0 })
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [sorting, setSorting] = React.useState<SortingState>([])

  const getOrganizations = async () => {
    const [organizationsData] = await Promise.all([
      fetch(ORGANIZATION_LIST).then(response => response.json()),
    ])
    setOrganizations(organizationsData)
    setOrganizationalStatistics({
      Accepted: organizationsData.filter((org: OrganizationListView) => org.IsApproved === true).length,
      Rejected: organizationsData.filter((org: OrganizationListView) => org.IsApproved === false).length,
      Pending: organizationsData.filter((org: OrganizationListView) => org.IsApproved === null).length,
      Contributor: organizationsData.filter((org: OrganizationListView) => org.IsContributor).length,
    })
    setLoading(false)
  }
  useEffect(() => { getOrganizations() }, [])

  const reload = async () => {
    setLoading(true)
    await getOrganizations()
    setLoading(false)
  }

  const onStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = Number(event.currentTarget.getAttribute('data-id'))
    const value = event.currentTarget.value
    setOrganizationApprovals([
      ...organizationApprovals,
      { Id: selectedId, IsApproved: value == 'accepted' },
    ])
  }

  const columns = React.useMemo<ColumnDef<OrganizationListView>[]>(
    () => organizationsTableColumns({ onStatusChange }), [])
  const table = useReactTable({
    data: organizations,
    columns,
    state: { sorting, },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  const finalizeChanges = async () => {
    setShowModal(false)
    setLoading(true)
    // const rawResponse = await fetch(ORGANIZATION_APPROVAL, {
    //   method: 'POST',
    //   body: JSON.stringify(organizationApprovals),
    //   headers: {
    //     Accept: 'application/json, text/plain',
    //     'Content-Type': 'application/json;charset=UTF-8',
    //   },
    // })
    // const content: Response = await rawResponse.json()
    const content = await submitForm(ORGANIZATION_APPROVAL, organizationApprovals)
    await getOrganizations()
    setToastMessage(`${content.type === 'SUCCESS'
      ? 'Changes Finalized ✅'
      : 'Something went wrong ❌'}`)
    setShowToast(true)
    setLoading(false)
  }

  if (loading) return (<Spinner />)
  return (
    <>
      <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center position-relative mb-2">
        <h1 className="display-6 text-primary">Organizations</h1>
        <Toolbar>
          <Button onClick={reload} title="Reload" className="">
            <ArrowClockwise />
            <div><small>Reload</small></div>
          </Button>
          <Button onClick={() => setShowModal(true)} title="Finalize">
            <Save />
            <div><small>Finalize</small></div>
          </Button>
        </Toolbar>
      </div>
      <BootstrapTable bordered hover responsive>
        <tbody>
          <tr>
            <td>All</td>
            <td className="text-center">{organizations.length}</td>
          </tr>
          <tr>
            <td>Accepted</td>
            <td className="text-center">{organizationalStatistics.Accepted}</td>
          </tr>
          <tr>
            <td>Rejected</td>
            <td className="text-center">{organizationalStatistics.Rejected}</td>
          </tr>
          <tr>
            <td>Pending</td>
            <td className="text-center">{organizationalStatistics.Pending}</td>
          </tr>
          <tr>
            <td>Contributor</td>
            <td className="text-center">{organizationalStatistics.Contributor}</td>
          </tr>
        </tbody>
      </BootstrapTable>
      <Table table={table} />
      <ToastContainer className="p-3 position-fixed bottom-0 end-0">
        <Toast
          className="ms-auto"
          show={showToast}
          onClose={() => setShowToast(false)}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">KFAS Learn</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Finalize Changes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to finalize the changes? This action cannot be
          undone. The organizations will receive their acceptance/rejection emails.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={finalizeChanges}>
            Finalize
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Organizations
