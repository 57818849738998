import "bootstrap/dist/css/bootstrap.css";
import "@popperjs/core";
import "bootstrap";

import * as React from "react";
// import * as ReactDOM from 'react-dom'
import * as ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store, history } from "./store/configureStore";
// import { store } from "./store/configureStore";
import { HistoryRouter as Router } from "redux-first-history/rr6";
import App from "./App";
// import registerServiceWorker from "./registerServiceWorker";

const container = document.getElementById("root") as Element;
const root = ReactDOM.createRoot(container);
root.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>
);
// ReactDOM.render(
//   <Provider store={store}>
//     <Router history={history}>
//       <App />
//     </Router>
//   </Provider>,
//   document.getElementById('root'))

// registerServiceWorker();
