import * as React from 'react'
import { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'
import Spinner from '../../components/Spinner/Spinner'
import { Applicant } from '../../types/applicant'
import { submitForm } from '../../utils/forms'
import {
  ColumnDef,
  getCoreRowModel,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable
} from '@tanstack/react-table'
import { approvalsTableColumns } from '../../data/ApprovalsTableColumns'
import { parseBooleanOrNull } from '../../utils/generic'
import { ArrowClockwise, Save, Download } from 'react-bootstrap-icons'
import Table from '../../components/Table/Table'
import Toolbar from '../../components/Toolbar/Toolbar'
import { downloadCSVFile, escapeData } from '../../utils/csv'

type Approval = {
  Id: number
  Approved: boolean | null
}

const Approvals = () => {
  const [applicants, setApplicants] = useState<Array<Applicant>>([])
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [approvals, setApprovals] = useState<Array<Approval>>([])
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [sorting, setSorting] = React.useState<SortingState>([])

  const getApplicantsData = async () => {
    const [applicantsData] = await Promise.all([
      fetch(`/api/data/getapprovals`).then(response => response.json()),
    ])
    const applicantsWithUrl = applicantsData.map((app:any)=>({
      ...app, url:``
    }));
    setApplicants(applicantsData)
    setLoading(false)
  }
  useEffect(() => { getApplicantsData() }, [])

  const reload = async () => {
    setLoading(true)
    await getApplicantsData()
    setLoading(false)
  }

  const onApprovalChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = Number(event.currentTarget.id)
    const value = parseBooleanOrNull(event.currentTarget.value)
    setApprovals(currentApprovals => {
      const approvalExists = currentApprovals
        .find(approval => approval.Id === selectedId)
      const updatedApprovals = approvalExists
        ? currentApprovals
          .map(approval => {
            if (approval.Id === selectedId)
              approval.Approved = value
            return approval
          })
        : [...currentApprovals, { Id: selectedId, Approved: value }]
      return updatedApprovals
    })
  }

  const generateCSV = () => {
    let lines = ''
    const columns = [
      'FirstNameEn',
      'MiddleNameEn',
      'LastNameEn',
      'FirstNameAr',
      'MiddleNameAr',
      'LastNameAr',
      'Event',
      'EventType',
      'FromDate',
      'ToDate',
      'Deadline',
      'Approved',
      'Status',
    ]
    applicants.forEach((applicant, index) => {
      const flattened =
        applicant.Data && typeof applicant.Data !== 'string'
          ? { ...applicant, ...applicant.Data }
          : { ...applicant }
      delete flattened.Data
      const tuples = Object.entries(flattened)
      if (index === 0) {
        for (const [key, value] of tuples) {
          if (!columns.includes(key)) { continue }
          lines += `"${escapeData(key)}",`
        }
        lines += '\n'
      }
      for (const [key, value] of tuples) {
        if (!columns.includes(key)) { continue }
        lines += value ? `"${escapeData(value as string)}",` : `"",`
      }
      lines += '\n'
    })
    const today = new Date()
    const date = `${today.getFullYear()}${today.getMonth() + 1}${today.getDate()}`
    downloadCSVFile(lines, `${date} - Approvals`)
    setToastMessage('Download Successful✅')
    setShowToast(true)
  }

  const columns = React.useMemo<ColumnDef<Applicant>[]>(
    () => approvalsTableColumns({ onApprovalChange }), [])
  const table = useReactTable({
    data: applicants,
    columns,
    state: { sorting, },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  const finalizeChanges = async () => {
    setShowModal(false)
    setLoading(true)
    const rawResponse = await submitForm(`/api/data/finalizeApprovals`, approvals)
    await getApplicantsData()
    setToastMessage('Changes Finalized✅')
    setShowToast(true)
    setLoading(false)
    setApprovals([])
  }

  if (loading) return (<Spinner />)
  return (
    <>
      <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center position-relative mb-2">
        <h1 className="display-6 text-primary">Approvals</h1>
        <Toolbar>
          <Button onClick={reload} title="Reload" className="">
            <ArrowClockwise />
            <div><small>Reload</small></div>
          </Button>
          <Button onClick={generateCSV}>
            <Download />
            <div><small>Download</small></div>
          </Button>
          <Button onClick={() => setShowModal(true)} title="Finalize">
            <Save />
            <div><small>Finalize</small></div>
          </Button>
        </Toolbar>
      </div>
      <Table table={table} />
      <ToastContainer className="p-3 position-fixed bottom-0 end-0">
        <Toast
          className="ms-auto"
          show={showToast}
          onClose={() => setShowToast(false)}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">KFAS Learn</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Finalize Changes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to finalize the changes? This action cannot be
          undone. The employees will receive their acceptance/rejection emails.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={finalizeChanges}>
            Finalize
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Approvals
